<template>
  <div class="row items-push">

    <div class="col-sm-4 col-xxl-4">
      <!-- Pending Orders -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ user.naskah_count }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Naskah Terbit</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="far fa-gem fs-3 text-primary"></i>
          </div>
        </div>
        <!-- <div class="bg-body-light rounded-bottom">
                  <a class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between" href="javascript:void(0)">
                    <span>View all orders</span>
                    <i class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"></i>
                  </a>
                </div> -->
      </div>
      <!-- END Pending Orders -->
    </div>
    <div class="col-sm-4 col-xxl-4">
      <!-- New Customers -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ user.market_count }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Naskah di Market</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="far fa-user-circle fs-3 text-primary"></i>
          </div>
        </div>
        <!-- <div class="bg-body-light rounded-bottom">
                  <a class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between" href="javascript:void(0)">
                    <span>View all customers</span>
                    <i class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"></i>
                  </a>
                </div> -->
      </div>
      <!-- END New Customers -->
    </div>
    <div class="col-sm-4 col-xxl-4">
      <!-- Messages -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ user.sales_count }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Terjual</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="far fa-paper-plane fs-3 text-primary"></i>
          </div>
        </div>
        <!-- <div class="bg-body-light rounded-bottom">
                  <a class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between" href="javascript:void(0)">
                    <span>View all messages</span>
                    <i class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"></i>
                  </a>
                </div> -->
      </div>
      <!-- END Messages -->
    </div>

    <div class="col-sm-12 col-xxl-4">
      <!-- Conversion Rate -->
      <div class="block block-rounded d-flex flex-column h-100 mb-0">
        <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
          <dl class="mb-0">
            <dt class="fs-3 fw-bold">{{ user.balance | currency }}</dt>
            <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0">Saldo</dd>
          </dl>
          <div class="item item-rounded-lg bg-body-light">
            <i class="fa fa-chart-bar fs-3 text-primary"></i>
          </div>
        </div>
        <!-- <div class="bg-body-light rounded-bottom">
          <a class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between"
            href="javascript:void(0)">
            <span>View statistics</span>
            <i class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"></i>
          </a>
        </div> -->
      </div>
      <!-- END Conversion Rate-->
    </div>

      <div class="col-sm-6 col-xxl-4">
        <!-- Conversion Rate -->
        <div class="block block-rounded d-flex flex-column h-100 mb-0">
          <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
            <dl class="mb-0">
              <dt class="fs-3 fw-bold green--text">{{ user.balance | currency }}</dt>
              <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0 green--text">Total Saldo Masuk</dd>
            </dl>
            <div class="item item-rounded-lg bg-body-light">
              <i class="fa-solid fa-money-check-dollar fs-3 text-primary"></i>
            </div>
          </div>
        </div>
        <!-- END Conversion Rate-->
      </div>

      <div class="col-sm-6 col-xxl-4">
        <!-- Conversion Rate -->
        <div class="block block-rounded d-flex flex-column h-100 mb-0">
          <div class="block-content block-content-full flex-grow-1 d-flex justify-content-between align-items-center">
            <dl class="mb-0">
              <dt class="fs-3 fw-bold red--text">{{ user.balance | currency }}</dt>
              <dd class="fs-sm fw-medium fs-sm fw-medium text-muted mb-0 red--text">Total Saldo Ditarik</dd>
            </dl>
            <div class="item item-rounded-lg bg-body-light">
              <i class="fa-solid fa-money-check fs-3 text-primary"></i>
            </div>
          </div>
          <!-- <div class="bg-body-light rounded-bottom">
            <a class="block-content block-content-full block-content-sm fs-sm fw-medium d-flex align-items-center justify-content-between"
              href="javascript:void(0)">
              <span>View statistics</span>
              <i class="fa fa-arrow-alt-circle-right ms-1 opacity-25 fs-base"></i>
            </a>
          </div> -->
        </div>
        <!-- END Conversion Rate-->
      </div>
    <!-- END Overview -->
  </div>
</template>

<script>
// import axios from "axios";

export default {
  props: ['user']
};
</script>
