<template>
  <div id="main-container">
    <!-- Hero -->
    <div class="bg-body-light">
      <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2">
          <div class="flex-grow-1">
            <h1 class="h3 fw-bold mb-2">
              Terbit Naskah
            </h1>
            <h2 class="fs-base lh-base fw-medium text-muted mb-0">
              Daftarkan naskah untuk mendapatkan nomor QRCBN.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- END Hero -->
    <div class="content">
      <form @submit.prevent="postNaskah">
        <div class="con-form">
          <div class="form-title mb-2">
            <b><i>Detail Buku</i></b>
          </div>
          <hr />

          <div class="row">
            <div class="col-12 col-md-4">
              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Judul</label>
                <input type="text" class="form-control form-control-alt" placeholder="Judul Naskah"
                  v-model="form.title" required>
              </div>
            </div>

            <div class="col-12 col-md-8">
              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Kepengarangan</label>
                <input type="text" class="form-control form-control-alt"
                  placeholder="Susunan Pengarang = Penulis, Editor, Pembuat Sampul" v-model="form.author" required>
              </div>
            </div>

            <div class="col-12 col-md-12">
              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Sinopsis</label>
                <textarea class="form-control form-control-alt" placeholder="Deskripsi Buku / Sinopsis"
                  v-model="form.description" required></textarea>
              </div>
            </div>

            <div class="col-12 col-md-6">

              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Edisi</label>
                <input type="text" class="form-control form-control-alt" placeholder="Edisi Terbit"
                  v-model="form.edisi" required>
              </div>

            </div>

            <div class="col-12 col-md-6">
              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Seri</label>
                <input type="text" class="form-control form-control-alt" placeholder="Nomor Seri" v-model="form.seri" required>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Tanggal Terbit</label>
                <input type="date" class="form-control form-control-alt" placeholder="Jumlah Halaman"
                  v-model="form.date" required>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Jumlah Halaman</label>
                <input type="text" class="form-control form-control-alt" placeholder="Jumlah Halaman"
                  v-model="form.page" required>
              </div>
            </div>

            <div class="col-12 col-md-12">
              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">URL Cover</label>
                <input type="text" class="form-control form-control-alt"
                  placeholder="URL Cover (Kosongi Jika Tidak Ada)" v-model="form.cover_url">
              </div>
            </div>
          </div>

          <div class="form-title mb-2">
            <b><i>Jenis Buku</i></b>
          </div>
          <hr />

          <div class="row">
            <div class="col-12 col-md-12">
              <div class="mb-4">
                <label class="form-label">Kelompok Usia Pembaca</label>
                <div class="space-x-2">
                  <div class="form-check form-check-inline">
                    <input v-model="form.readers" class="form-check-input" type="radio" value="anak">
                    <label class="form-check-label" for="example-radios-inline1">Anak - anak</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input v-model="form.readers" class="form-check-input" type="radio" value="dewasa">
                    <label class="form-check-label" for="example-radios-inline2">Dewasa</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input v-model="form.readers" class="form-check-input" type="radio" value="semua usia">
                    <label class="form-check-label" for="example-radios-inline3">Semua Usia</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-12">
              <div class="mb-4">
                <label class="form-label">Kategori Naskah</label>
                <div class="space-x-2">
                  <div class="form-check form-check-inline">
                    <input v-model="form.category" class="form-check-input" type="radio" value="fiksi">
                    <label class="form-check-label" for="example-radios-inline1">Fiksi</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input v-model="form.category" class="form-check-input" type="radio" value="non fiksi">
                    <label class="form-check-label" for="example-radios-inline2">Non Fiksi</label>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-12 col-md-12">
              <div class="mb-4">
                <label class="form-label">Media Terbit</label>
                <div class="space-x-2">
                  <div class="form-check form-check-inline">
                    <input v-model="form.type" class="form-check-input" type="radio" value="cetak">
                    <label class="form-check-label" for="example-radios-inline1">Buku Cetak / Fisik</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input v-model="form.type" class="form-check-input" type="radio" value="ebook">
                    <label class="form-check-label" for="example-radios-inline2">Electronic Book (e-Book)</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="form-title mb-2">
            <b><i>Tambahkan Link Penjualan Buku</i></b>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">Platform / Marketplace</label>
                <input type="text" class="form-control form-control-alt" placeholder="Nama Website / Marketplace"
                  v-model="form.tautan.platform">
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="mb-4">
                <label class="form-label" for="example-text-input-alt">URL Platform / Marketplace</label>
                <input type="text" class="form-control form-control-alt" placeholder="URL Website / Marketplace"
                  v-model="form.tautan.link">
              </div>

            </div>
          </div>

          <div class="text-right">
            <b-button class="btn add_link" variant="light" @click="saveLink">Simpan Link</b-button>
          </div>

          <hr />
          <div v-if="form.links.length > 0" class="form-title mb-2">
            <b><i>Data Link Penjualan</i></b>
          </div>

          <div class="row" v-for="(link, index) in form.links" :key="index">
            <div class="col col-md-4">
              <div class="jenis-title">
                <b>{{ link.platform }}</b>
              </div>
            </div>
            <div class="col col-md-8">
              <div>{{ link.link }}</div>
            </div>
          </div>

          <!-- {{ form.links }} -->

          <div class="text-right">
            <vs-button type="submit" class="btn btn-primary px-5" :loading="loading.submit">Terbitkan</vs-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    data: () => ({
      form: {
        title: "",
        author: "",
        description: "",
        edisi: "",
        seri: "",
        date: "",
        page: "",
        cover_url: "",
        readers: "",
        category: "",
        type: "",

        tautan: {
          platform: "",
          link: "",
        },

        links: [],
      },

      loading: {
        submit: false,
      },

      errormsg: "",
    }),

    methods: {
      saveLink() {
        if(this.form.title == "" || this.form.author == "") {
          this.openNotification(
            "top-right",
            "danger",
            "1000",
            "gagal",
            "Lengkapi data terlebih dahulu"
          )
        }

        if (this.form.tautan.platform == "" || this.form.tautan.link == "") {
          this.openNotification(
            "top-right",
            "danger",
            "10000",
            "Gagal",
            "Lengkapi Data Link"
          );
        } else {
          this.form.links.push({
            platform: this.form.tautan.platform,
            link: this.form.tautan.link,
          });

          this.form.tautan.platform = "";
          this.form.tautan.link = "";

          this.openNotification(
            "top-right",
            "success",
            "10000",
            "Sukses",
            "Link Berhasil Ditambahkan, Silahkan Tambahkan Link Lainnya Jika Ada"
          );
        }
      },

      postNaskah() {

        this.loading.submit = true;

        const token = localStorage.getItem("access_token");

        var formData = new FormData();

        formData.append("title", this.form.title);
        formData.append("author", this.form.author);
        formData.append("description", this.form.description);
        formData.append("edisi", this.form.edisi);
        formData.append("seri", this.form.seri);
        formData.append("date", this.form.date);
        formData.append("page", this.form.page);
        formData.append("cover_url", this.form.cover_url);
        formData.append("readers", this.form.readers);
        formData.append("category", this.form.category);
        formData.append("publish_type", this.form.type);
        formData.append("publish_date", this.form.date);

        formData.append("links", JSON.stringify(this.form.links));

        axios
          .post("naskah", formData, {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            this.openNotification(
              "top-right",
              "success",
              "10000",
              "Sukses",
              response.data.message
            );
            this.$router.push({ name: "dashboard" });
          })
          .catch((error) => {
            this.errormsg = error.response.data.error;
            console.log(this.errormsg.title);
            this.loading.submit = false;
          })
          .finally(() => (this.loading.submit = false));
      },
    },
  };  

  

</script>

<style>
.dashboard-title {
  background-color: #def5f7;
}

#sinopsis {
  font-size: 0.95em;
}

.jenis-radio {
  text-align: left !important;
}

.add_link {
  background: transparent;
  font-size: 1em;
  text-transform: uppercase;
}
</style>
<style scoped>
@import '../../../public/oneui/assets/css/oneui.min.css';
</style>