<template>
  <div>
    <!-- Hero -->
    <div class="bg-body-light">
      <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2">
          <div class="flex-grow-1">
            <h1 class="h3 fw-bold mb-2">
              Data Naskah
            </h1>
            <h2 class="fs-base lh-base fw-medium text-muted mb-0">
              Data semua naskah yang sudah terbit dan berQRCBN.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- END Hero -->

    <div class="content">
      <input type="text" class="form-control" placeholder="Cari Judul Naskah" v-model="search" @input="getData">

      <div class="table-responsive">
                <table class="table table-bordered table-striped table-vcenter">
                  <thead>
                    <tr>
                      <th class="text-center" style="width: 300px !important;">
                        <i class="fa fa-qrcode"></i>
                      </th>
                      <th>Judul Naskah</th>
                      <th>Kepengarangan</th>
                      <th>Tanggal Terbit</th>
                      <th>Market</th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="i" v-for="(tr, i) in naskahs.data" :data="tr" :class="{'table-danger': tr.is_blocked === 1}">
                      <td class="text-center" :id="tr.qrcbn_id">
                        <div>
                            <vue-qr :text="'https://www.qrcbn.com/check/' + tr.qrcbn_id" logoSrc="/qrlogo.png" :size="300"></vue-qr>
                        </div>
                        <span class="fs-sm"><b>{{ tr.qrcbn_id }}</b></span>
                      </td>
                      <td class="fw-semibold fs-sm">
                        {{ tr.title }}
                        <span v-if="tr.is_blocked === 1" class="text-danger"> (Naskah diblokir)</span>
                      </td>
                      <td class="fs-sm">{{ tr.author }}</td>
                      <td class="fs-sm">{{ tr.publish_date }}</td>
                      <td class="text-center">
                        <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-success-light text-success si si-check" v-if="tr.market == true"></span>
                        <span class="fs-xs fw-semibold d-inline-block py-1 px-3 rounded-pill bg-danger-light text-danger fa fa-x" v-else></span>
                      </td>
                      <td class="text-center">
                        <div class="btn-group">
                          <button type="button" class="btn-sm btn-alt-secondary" data-bs-toggle="tooltip" title="Download QRCode" @click="printThis(tr.qrcbn_id, tr.title)">
                            <i class="si si-printer"></i>
                          </button>
                          <button type="button" class="btn-sm btn-alt-secondary" data-bs-toggle="tooltip" title="Edit Naskah">
                            <a :href="'/update/' + tr.qrcbn_id"><i class="si si-note"></i></a>
                          </button>
                          <button type="button" class="btn-sm btn-alt-secondary" data-bs-toggle="tooltip" title="Atur Link Penjualan" @click="getLink(tr.qrcbn_id, tr.title)">
                            <i class="si si-tag"></i>
                          </button>
                          <button type="button" class="btn-sm btn-alt-secondary" data-bs-toggle="tooltip" title="Jual EBook" v-if="tr.market != true" @click="marketModal(tr.id, tr.qrcbn_id, tr.cover_url, tr.title)">
                            <i class="si si-wallet"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

      <vs-pagination v-model="page" :length="naskahs.last_page" @input="getData" />

      <div class="text-center mt-3">
        <Adsense data-ad-client="ca-pub-3076489163946110" data-ad-slot="6109811926">
        </Adsense>
      </div>

      <v-dialog v-model="linkModal" fullscreen style="z-index: 1032 !important;">
        <v-card-title class="text-body-2 grey lighten-2">
          Sales Links for <strong>{{ editing.title }}</strong>
        </v-card-title>
        <v-card color="primary" dark v-if="loading_links">
          <v-card-text>
            Loading Your Sales Links
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>

        <v-card>
          <v-simple-table fixed-header height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Platform
                  </th>
                  <th class="text-left">
                    Link
                  </th>
                  <th class="text-left">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody v-if="links.length != 0">
                <tr v-for="item in links" :key="item.id">
                  <td>{{ item.platform }}</td>
                  <td>{{ item.link }}</td>
                  <td>
                    <v-btn text x-small color="primary" @click="startEditLink(item.id, item.platform, item.link)">
                      Edit
                    </v-btn>

                    <v-btn text x-small color="error" @click="startDeleteLink(item.id, item.platform)">
                      Delete
                    </v-btn>
                  </td>
                </tr>
              </tbody>

              <tbody v-else>
                <tr>
                  <td colspan="3" class="text-center font-weight-bold">No Links Found</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary darken-1" text @click="linkModal = false">
              Close
            </v-btn>

            <v-btn color="primary darken-1" @click="addLinkModal = true">
              Add New Sales Link
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addLinkModal" max-width="400px">
        <v-card>

          <v-card-title class="text-body-2 grey lighten-2">
            Add Sales Link for <strong>{{ editing.title }}</strong>
          </v-card-title>

          <v-row no-gutters>
            <v-col cols="12">
              <div class="dialog-form px-5 mb-5">
                <span class="mb-3">Platform</span>
                <v-text-field solo hide-details="auto" v-model="addLink.platform"></v-text-field>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="dialog-form px-5 mb-5">
                <span class="mb-3">Link</span>
                <v-text-field solo hide-details="auto" v-model="addLink.link"></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary darken-1" text @click="addLinkModal = false">
              Cancel
            </v-btn>

            <v-btn color="primary darken-1" @click="saveLink(editing.id)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editLinkModal" max-width="400px">
        <v-card>

          <v-card-title class="text-body-2 grey lighten-2">
            Edit Sales Link for <strong>{{ editing.title }}</strong>
          </v-card-title>

          <v-row no-gutters>
            <v-col cols="12">
              <div class="dialog-form px-5 mb-5">
                <span class="mb-3">Platform</span>
                <v-text-field solo hide-details="auto" v-model="editLink.platform"></v-text-field>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="dialog-form px-5 mb-5">
                <span class="mb-3">Link</span>
                <v-text-field solo hide-details="auto" v-model="editLink.link"></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary darken-1" text @click="editLinkModal = false">
              Cancel
            </v-btn>

            <v-btn color="primary darken-1" @click="updateLink(editing.id, editLink.id)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteLinkModal" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Delete Sales Link?
          </v-card-title>

          <v-card-text>
            Are you sure, to delete <strong>{{ deleteLink.platform }}</strong> sales link for <strong>{{ editing.title }}
              ?</strong>.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary darken-1" text @click="deleteLinkModal = false">
              Cancel
            </v-btn>

            <v-btn color="error darken-1" @click="goDeleteLink">
              Agree
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="marketDialog" fullscreen style="z-index: 1032 !important;">
        <MarketModal :cover="market.cover" :qrcbn="market.qrcbn" :title="market.title" :book_id="market.id"
          @close-dialog="marketDialog = false" @updated-data="getData"></MarketModal>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";

import axios from "axios";
import MarketModal from "../../components/library/MarketModal.vue";
export default {
  components: {
    VueQr,
    MarketModal
  },

  data() {
    return {
      loading_data: false,
      loading_links: false,

      linkModal: false,
      addLinkModal: false,
      editLinkModal: false,
      deleteLinkModal: false,
      marketDialog: false,

      search: "",
      page: 1,

      naskahs: [],

      links: [],
      output: null,
      editing: {
        id: "",
        title: "",
      },

      addLink: {
        platform: "",
        link: "",
      },

      editLink: {
        platform: "",
        link: "",
        id: ""
      },

      deleteLink: {
        id: "",
        platform: ""
      },

      market: {
        id: "",
        qrcbn: "",
        title: "",
        cover: ""
      }
    };
  },

  methods: {
    marketModal(id, qrcbn, cover, title) {
      this.market.id = id;
      this.market.qrcbn = qrcbn;
      this.market.cover = cover;
      this.market.title = title;

      this.marketDialog = true
    },
    getLink(id, title) {
      this.loading_links = true;
      this.editing.id = id;
      this.editing.title = title;
      this.linkModal = true;

      axios
        .get("links/" + id, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.links = response.data.data.links;
          console.log(this.links);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading_links = false));
    },

    startEditLink(id, platform, link) {
      this.editLinkModal = true
      this.editLink.id = id;
      this.editLink.platform = platform;
      this.editLink.link = link;
    },

    startDeleteLink(id, platform) {
      this.deleteLinkModal = true;
      this.deleteLink.id = id;
      this.deleteLink.platform = platform;
    },

    saveLink(id) {
      this.loading_data = true;

      axios
        .post(
          "addLink",
          {
            id: id,
            platform: this.addLink.platform,
            link: this.addLink.link,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.addLinkModal = false;
          this.getLink(response.data.data.naskah.qrcbn_id, response.data.data.naskah.title);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading_data = false));
    },

    updateLink(qrcbn, id) {
      this.loading_data = true;

      axios
        .post(
          "updateLink",
          {
            qrcbn: qrcbn,
            id: id,
            platform: this.editLink.platform,
            link: this.editLink.link,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.editLinkModal = false;
          this.getLink(response.data.data.naskah.qrcbn_id, response.data.data.naskah.title);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading_data = false));
    },

    goDeleteLink() {
      this.loading_data = true;

      axios
        .post(
          "deleteLink", {
          qrcbn: this.editing.id,
          id: this.deleteLink.id,
        },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.deleteLinkModal = false;
          this.getLink(response.data.data.naskah.qrcbn_id, response.data.data.naskah.title);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading_data = false));
    },

    getData() {
      this.loading_data = true;
      var token = localStorage.getItem("access_token");

      axios
        .post(
          "my_naskah2?page=" + this.page,
          {
            keyword: this.search,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          this.naskahs = response.data.data.naskah;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading_data = false));
    },

    async printThis(id, title) {
      console.log("printing..");
      const el = document.getElementById(id);

      this.$html2canvas(el, {
        backgroundColor: "#ffffff",
        scale: 5
      }).then((canvas) => {
        var imgData = canvas.toDataURL("image/jpeg");
        this.fileDownload(imgData, id, title);
      });
    },

    fileDownload(downloadUrl, id, title) {
      let aLink = document.createElement("a");
      aLink.style.display = "none";
      aLink.href = downloadUrl;
      aLink.download = id + " " + title + ".jpg";
      // Trigger click-then remove
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style>
.dashboard-title {
  background-color: #def5f7;
}

.qr-item {
  font-size: 1.2em;
}
</style>

<style scoped>
@import '../../../public/oneui/assets/css/oneui.min.css';
</style>